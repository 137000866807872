import { template as template_8b9ec74885164e79839982e989720b42 } from "@ember/template-compiler";
const FKLabel = template_8b9ec74885164e79839982e989720b42(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
