import { template as template_3365c71c1f6244ab874df31aaa072241 } from "@ember/template-compiler";
const FKText = template_3365c71c1f6244ab874df31aaa072241(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
