import { template as template_54d2e0dd23c7464a89ffea17133d33cd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_54d2e0dd23c7464a89ffea17133d33cd(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
